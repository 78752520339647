<template>
  <div class="card gx-0" style="width:19rem; height:16rem; margin-left:3.5rem; margin-top:2rem;">
    <div class="card-header pt-1">
        <h5><slot name="asunto"></slot></h5>
    </div>
    <div class="card-body d-flex flex-column">
      <h5 class="card-title">
          <slot name="titulo"></slot>
      </h5>
      <div class="card-text">
        <slot name="cuerpo"></slot>
      </div>
    </div>
    <div class="" style="margin-top: auto; margin-left: auto;"><small><code><slot name="leyenda"></slot></code></small></div>
  </div>
</template>

<script>
export default {
    
}
</script>
